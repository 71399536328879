import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

interface IMultipleSelectProps {
  label: string;
  options: any;
  loading: boolean;
  value: any;
  setValue: (value: any) => void;
  onCreate: (newValue: string) => void;
}

const MultipleSelect = ({ label, options, loading, setValue, value, onCreate }: IMultipleSelectProps) => {
  if (loading) {
    <CircularProgress />;
  }

  if (!options?.length) {
    return null;
  }

  return (
    <Box>
      <Typography>{label}</Typography>
      <Autocomplete
        multiple
        value={value}
        onChange={(event, newValue, reason, details) => {
          if (reason === 'createOption') {
            onCreate(details?.option);
          } else if (reason === 'selectOption' && details?.option?.id === 'create') {
            onCreate(details?.option?.inputValue);
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(newOptions, params) => {
          const filtered = filter(newOptions, params);

          const { inputValue } = params;
          const isExisting = newOptions.some((option) => inputValue === option.name);

          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: `Create "${inputValue}"`,
              id: 'create',
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        groupBy={(option) => option.groupName}
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: 0,
          },
        }}
        freeSolo
        renderInput={(params) => <TextField {...params} />}
      />
    </Box>
  );
};

export default MultipleSelect;
