// TODO remove UsersRoles, we get this from BE
export enum UserRoles {
  'Admin' = 1,
  'Underwriter' = 2,
  'User' = 3,
  'Funder' = 4,
}

// TODO change permissions with new enum from BE
export enum Permissions {
  create = 'CREATE', // should be spread based on requirements
  viewDashboard = 'VIEW_DASHBOARD',
  viewFunds = 'VIEW_FUNDING_REQUEST_TAB',
  viewFundList = 'VIEW_FUNDING_LIST',
  viewManualReviewList = 'VIEW_MANUAL_REVIEWS_LIST',
  viewSources = 'VIEW_SOURCES_LIST',
  viewIntegration = 'VIEW_INTEGRATION',
  viewChannelsForSource = 'VIEW_CHANNELS_FOR_SOURCE',
  deleteSource = 'DELETE_SOURCE',
  createSource = 'CREATE_SOURCE',
  changeIntegrationFields = 'CHANGE_INTEGRATION_FIELDS',
  channelsStatusChange = 'CHANNELS_STATUS_CHANGE',
  userManagement = 'VIEW_USER_MANAGEMENT',
  viewIntegrations = 'VIEW_INTEGRATIONS', // @deprecated as integrations should be deleted
  reviewFund = 'FUNDING_REQUEST_RESOLVE',
  tenantSettings = 'TENANT_SETTINGS',
  revertApplication = 'REVERT_APPLICATION', // closed, as business req is not clear
  changeFunder = 'FUNDING_REQUEST_ASSIGN',
  resendLoanDocument = 'RESEND_LOAN_DOCUMENTS',
  viewApplicationLogs = 'VIEW_LOGS',
  viewManualReview = 'VIEW_MANUAL_REVIEW_TAB',
  viewDocuments = 'VIEW_DOWNLOAD_ALL_DOCUMENTS',
  assignReason = 'MANUAL_REVIEW_ASSIGN',
  viewProductList = 'VIEW_PRODUCT_LIST',
  viewProductGeneralInformation = 'VIEW_PRODUCT_GENERAL_INFORMATION',
  viewProductIntegration = 'VIEW_INTEGRATION_DETAILS',
  viewProductSourceChannelDetails = 'VIEW_PRODUCT_SOURCE_CHANNEL_DETAILS',
  viewProductHistory = 'VIEW_HISTORY',
  viewProductHistoryDetails = 'VIEW_HISTORY_DETAILS',
  createProduct = 'CREATE_PRODUCT',
  deleteProduct = 'DELETE_PRODUCT',
  editProduct = 'EDIT_PRODUCT',
  editIntegrationFields = 'EDIT_INTEGRATION_FIELDS',
  integrationFileUpload = 'INTEGRATION_FILE_UPLOAD',
  cloneProduct = 'CLONE_PRODUCT',
  changeProductSourceChannelStatus = 'PRODUCT_SOURCE_CHANNEL_STATUS_CHANGE',
  viewSourceChannelsList = 'VIEW_SOURCE_CHANNELS_LIST',
  viewSourceChannelInformation = 'VIEW_SOURCE_CHANNEL_INFORMATION',
  viewSourceChannelPostingDetails = 'VIEW_SOURCE_CHANNEL_POSTING_DETAILS',
  viewSourceChannelDecisionTree = 'VIEW_SOURCE_CHANNEL_DECISION_TREE',
  viewDecisionTreeFilter = 'VIEW_DECISION_TREE_FILTER',
  viewSourceChannelHistory = 'VIEW_SOURCE_CHANNEL_HISTORY',
  viewSourceChannelHistoryDetails = 'VIEW_SOURCE_CHANNEL_HISTORY_DETAILS',
  deleteSourceChannel = 'DELETE_SOURCE_CHANNEL',
  createSourceChannel = 'CREATE_SOURCE_CHANNEL',
  sourceChannelInformationChange = 'SOURCE_CHANNEL_INFORMATION_CHANGE',
  resetPassword = 'RESET_PASSWORD',
  createSourceChannelDecisionTree = 'CREATE_SOURCE_CHANNEL_DECISION_TREE',
  createDecisionTreeFilter = 'CREATE_DECISION_TREE_FILTER',
  changeDecisionTreeFilter = 'CHANGE_DECISION_TREE_FILTER',
  deleteDecisionTreeFilter = 'DELETE_DECISION_TREE_FILTER',
  viewDecisionJourney = 'VIEW_DECISION_JOURNEY',
  viewAppJourney = 'VIEW_APPLICATION_JOURNEY',
  manualReviewSelfAssignUnassign = 'MANUAL_REVIEW_SELF_ASSIGN',
  manualReviewAcceptReject = 'MANUAL_REVIEW_RESOLVE',
  manualReviewUploadDocument = 'MANUAL_REVIEW_UPLOAD_DOCUMENTS',
  stipulationApproveNotAccept = 'STIPULATION_DOCUMENTS_RESOLVE',
  fundSelfAssignUnassign = 'FUNDING_REQUEST_SELF_ASSIGN',
  uploadDocuments = 'UPLOAD_DOCUMENTS',
  viewSourceReportsPage = 'VIEW_SOURCE_REPORTS_PAGE',
  viewSourceComparisonReportsPage = 'VIEW_SOURCE_COMPARISON_REPORTS_PAGE',
  viewApplicationListReportsPage = 'VIEW_APPLICATION_LIST_REPORTS_PAGE',
  viewFundingReportPage = 'VIEW_FUNDING_REPORT_PAGE',
  viewNotesTab = 'VIEW_NOTES_TAB',
  editNotesTab = 'EDIT_NOTES_TAB',
  editTags = 'EDIT_TAGS',
}
