import { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import MultipleSelect from 'components/shared/Select/MultipleSelect';

import { ILeadsManualReviewReason } from '@types';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { manualReviewMiddleware, manualReviewSelector } from '../../../redux/slices/manualReviews';
import { IAanReason } from '../../../redux/slices/manualReviews/initialState';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { CustomButton } from '../../shared';
import CustomTextArea from '../../shared/CustomInputs/CustomTextArea';
import Title from '../../UI/Title';

export interface IRejectApplicantModalProps {
  id: string;
  manualReviewReason: ILeadsManualReviewReason;
  mainNote: string;
}

const RejectApplicantModal = ({ id, manualReviewReason, mainNote }: IRejectApplicantModalProps) => {
  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.RejectApplicantModal));
  };
  const [note, setNote] = useState<string>('');
  const [reasons, setReasons] = useState<IAanReason[]>([]);
  const manualReviewReasonLoading = useAppSelector(manualReviewSelector.manualReviewReasonLoading);
  const aanReasons = useAppSelector(manualReviewSelector.aanReasons);
  const aanReasonsLoading = useAppSelector(manualReviewSelector.aanReasonsLoading);

  const onReject = () => {
    let aanReasonIds: string[] = [];

    if (reasons.length) {
      aanReasonIds = reasons.map((el: IAanReason) => el.id);
    }

    const trimmedMainNote = mainNote?.trim();
    const trimmedNote = note.trim();

    dispatch(
      manualReviewMiddleware.resolveManualReviewReason(
        id!,
        manualReviewReason!,
        2,
        `${trimmedMainNote ?? ''}${trimmedMainNote && trimmedNote ? ' ' : ''}${trimmedNote ? `[${trimmedNote}]` : ''}`,
        aanReasonIds,
      ),
    );
  };

  const onNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    setNote(value);
  };

  const onReasonCreate = (value: string) => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.CreateReasonModal,
        props: { id: manualReviewReason.reason, name: value },
      }),
    );
  };

  useEffect(() => {
    if (manualReviewReason?.reason) {
      dispatch(manualReviewMiddleware.getAanReasons(manualReviewReason?.reason));
    }
  }, [manualReviewReason]);

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <DialogTitle>
          <Title
            title="Reject Application"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box marginBottom="20px">
            <MultipleSelect
              label="Reject Reason"
              options={aanReasons}
              loading={aanReasonsLoading}
              value={reasons}
              setValue={setReasons}
              onCreate={onReasonCreate}
            />
          </Box>
          <CustomTextArea onChange={onNoteChange} label="Reject Note" placeholder="Type..." />
          <Box display="flex" justifyContent="space-around" marginTop="20px">
            <CustomButton variant="outlined" onClick={onClose} loading={manualReviewReasonLoading}>
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="error"
              disabled={!note.length && !reasons.length}
              onClick={onReject}
              sx={{ backgroundColor: '#FF497F', borderColor: note.length && '#FF497F' }}
              loading={manualReviewReasonLoading}
            >
              Reject Application
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default RejectApplicantModal;
